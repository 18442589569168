import { axiosIns } from '@/libs/axios'

const loginApi = {
  // 로그인
  async fetchLogin(loginInf) {
    return axiosIns.post('/cert/login', loginInf);
  },
  // ID 중복 체크
  async checkDuplicateId(payload) {
    return axiosIns.post('/cert/checkId', {
        mngrId: payload.mngrId
    });
  },
  // SMS 인증번호 전송
  async sendMobCtf(payload) {
    return axiosIns.post('/cert/certSms', {
      mngrId: payload.mngrId,
      mngrPwd: payload.mngrPwd,
      mngrMblTlno: payload.mngrMblTlno
    });
  },
  // SMS 인증번호 검증
  async checkMobCtf(payload) {
    return axiosIns.post('/cert/checkSms', {
      mngrId: payload.mngrId,
      mngrMblTlno: payload.mngrMblTlno,
      smsCfmChr: payload.smsCfmChr,
      isResetPw: payload.isResetPw
    });
  },
  // 지역본부 목록 조회
  fetchGrpList() {
    return axiosIns.get('/cert/findGroupList');
  },
  // 단지 목록 조회
  fetchSbdNmList(payload) {
    return axiosIns.get('/cert/findSubdivisionListForJoin', {
      params: {
        grpSn: payload.grpSn
      }
    });
  },
  // 회원가입
  async register(payload) {
    return axiosIns.post('/cert/join', payload);
  },
  // 비밀번호 재설정
  async resetPassword(payload) {
    return axiosIns.put('/cert/resetPassword', payload);
  },
  // 비밀번호 6개월 표시안함
  async unsetPassword(payload) {
    return axiosIns.put('/cert/unsetPassword', payload);
  },
  // 비밀번호 재설정 전 관리자 확인
  async checkMngrIdBeforeReset(payload) {
    return axiosIns.post('/cert/checkMngrIdBeforeReset', {
      mngrId: payload.mngrId
  });
  },
}

export default loginApi;
