import loginApi from '@/api/login/loginApi';
import cmnApi from '@/api/cmn/cmnApi';
import cookie from '@/libs/vue-cookies';
import { useJwt } from '@/libs/axios';

const getInitState = () => {
  return {
    isLoggedIn: false,
    grpList: [],
    sbdNmList: [],
    mobCtfMngrId: ''
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    getIsLoggedIn(state) {
      return state.isLoggedIn
    },
    getGrpList(state) {
      return state.grpList.map(v => ({
        id: v.grpSn,
        name: v.grpNm,
      }));
    },
    getSbdNmList(state) {
      return state.sbdNmList;
    },
    getMobCtfMngrId(state) {
      return state.mobCtfMngrId;
    }
  },
  mutations: {
    INIT_LOGIN(state) {
      Object.assign(state, getInitState());
    },
    INIT_SBD_NM_LIST(state) {
      state.sbdNmList = []
    },
    INIT_SBD_FL(state) {
      state.grpList = []
      state.sbdNmList = []
    },
    SET_IS_LOGGED_IN(state, payload) {
      state.isLoggedIn = payload
    },
    SET_GRP_LIST(state, { grpList }) {
      state.grpList = grpList
    },
    SET_SBD_NM_LIST(state, { sbdNmList }) {
      state.sbdNmList = sbdNmList
    },
    SET_MOB_CTF_MNGR_ID(state, { mobCtfMngrId }) {
      state.mobCtfMngrId = mobCtfMngrId
    },
  },
  actions: {
    async initLogin({ commit }) {
      commit('INIT_LOGIN');
    },
    async initSbdNmList({ commit }) {
      commit('INIT_SBD_NM_LIST')
    },
    async initSbdFl({ commit }) {
      commit('INIT_SBD_FL')
    },
    async login(_, { mngrId, mngrPwd }) {
      const loginInf = { mngrId, mngrPwd }
      const { data } = await loginApi.fetchLogin(loginInf)
      return data
    },
    async updateRefreshToken(_, payload) {
      await useJwt.updateRefreshToken({
        refshTkn: payload.refreshToken,
        oldRefshTkn: payload.oldRefreshToken
      });
    },
    async processLogin({ commit }, payload) {
      // 토큰 정보 쿠키에 저장장
      cookie.saveAccessToken(payload.accessToken);
      cookie.saveRefreshToken(payload.refreshToken);
      cookie.saveOldRefreshToken(payload.oldRefreshToken);

      commit('SET_IS_LOGGED_IN', true);
    },
    async logout({ commit }) {
      cookie.delAccessToken();
      cookie.delRefreshToken();
      cookie.delOldRefreshToken();
      cookie.delMngrInf();

      commit('SET_IS_LOGGED_IN', false);
    },
    async fetchGrpList({ commit }) {
      const { data } = await loginApi.fetchGrpList();
      commit('SET_GRP_LIST', { grpList: data.resData });
    },
    async fetchSbdNmList({ commit }, payload) {
      // const { data } = await loginApi.fetchSbdNmList(payload);
      const { data } = await cmnApi.fetchSbdNmListForJoin(payload);
      commit('SET_SBD_NM_LIST', { sbdNmList: data.resData });
    },
    async checkDuplicateId(_, payload) {
      const { data } = await loginApi.checkDuplicateId(payload);
      return data;
    },
    async sendMobCtf(_, payload) {
      const { data } = await loginApi.sendMobCtf(payload);
      return data;
    },
    async checkMobCtf(_, payload) {
      const { data } = await loginApi.checkMobCtf(payload);
      return data;
    },
    async udpateMobCtfMngrId({ commit }, payload) {
      commit('SET_MOB_CTF_MNGR_ID', { mobCtfMngrId: payload })
    },
    async register(_, payload) {
      const { data } = await loginApi.register(payload);
      return data;
    },
    async resetPassword(_, payload) {
      const { data } = await loginApi.resetPassword(payload);
      return data;
    },
    async unsetPassword(_, payload) {
      const { data } = await loginApi.unsetPassword(payload);
      return data;
    },
    async checkMngrIdBeforeReset(_, payload) {
      const { data } = await loginApi.checkMngrIdBeforeReset(payload);
      return data;
    },
  },
}
