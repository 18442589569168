import app from '@/main';
import store from '@/store';
import { msgAlertBox } from '@/utils/modal';

/**
 *  로그인
 */
export default [
  {
    path: "/login",
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: "full",
    },
    children: [
      {
        path: "",
        redirect: "/login/Login",
      },
      {
        path: "Login",
        name: "LoginFrm",
        component: () => import('@/views/login/components/LoginFrm.vue'),
        meta: {
          layout: "full",
        },
      },
      {
        path: "Register",
        component: () => import('@/views/login/components/Register.vue'),
        meta: {
          layout: "full",
        },
        children: [
          {
            path: "",
            redirect: "/login/Register/RegisterFrm",
          },
          {
            path: "RegisterFrm",
            name: "RegisterFrm",
            component: () => import('@/views/login/components/RegisterFrm.vue'),
            meta: {
              layout: "full",
            },
          },
          {
            path: "RegisterFrm1",
            name: "RegisterFrm1",
            component: () => import('@/views/login/components/RegisterFrm1.vue'),
            meta: {
              layout: "full",
              pageTitle: "관리사무소가입"
            },
          },
          {
            path: "RegisterFrm2",
            name: "RegisterFrm2",
            component: () => import('@/views/login/components/RegisterFrm2.vue'),
            meta: {
              layout: "full",
              pageTitle: "Q+매니저가입"
            },
          },
        ]
      },
      {
        path: "ResetPwd",
        component: () => import('@/views/login/components/ResetPwd.vue'),
        meta: {
          layout: "full",
        },
        children: [
          {
            path: "",
            redirect: "/login/ResetPwd/ResetPwdFrm1",
          },
          {
            path: "ResetPwdFrm1",
            name: "ResetPwdFrm1",
            component: () => import('@/views/login/components/ResetPwdFrm1.vue'),
            meta: {
              layout: "full",
            },
          },
          {
            path: "ResetPwdFrm2",
            name: "ResetPwdFrm2",
            component: () => import('@/views/login/components/ResetPwdFrm2.vue'),
            meta: {
              layout: "full",
            },
            beforeEnter: (to, from, next) => {
              const isValid = from.name === 'ResetPwdFrm1' && store.state.login.loginStore.mobCtfMngrId;

              if (!isValid) {
                msgAlertBox(app, '본인 인증 후 비밀번호를 재설정해주세요.');
                next('/login/ResetPwd/ResetPwdFrm1');
              } else {
                next();
              }
            }
          },
          {
            path: "ResetPwdFrm3",
            name: "ResetPwdFrm3",
            component: () => import('@/views/login/components/ResetPwdFrm3.vue'),
            meta: {
              layout: "full",
            },
            beforeEnter: (to, from, next) => {
              const isValid = from.name === 'ResetPwdFrm2' && store.state.login.loginStore.mobCtfMngrId;

              if (!isValid) {
                msgAlertBox(app, '본인 인증 후 비밀번호를 재설정해주세요.');
                next('/login/ResetPwd/ResetPwdFrm1');
              } else {
                next();
              }
            }
          },
        ]
      }
    ]
  }
]
